import { template as template_9f95e3a800044ba8b16c30991a140007 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_9f95e3a800044ba8b16c30991a140007(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
