import { template as template_1c1ab53a04c049b1951f93acfcdc9360 } from "@ember/template-compiler";
const FKLabel = template_1c1ab53a04c049b1951f93acfcdc9360(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
