import { template as template_389e4be894e244d09e1b69c2d6c23344 } from "@ember/template-compiler";
const FKControlMenuContainer = template_389e4be894e244d09e1b69c2d6c23344(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
